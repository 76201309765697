import {
  ContactCtaHeadingSection,
  FeaturedBlogs,
  FeaturedPropertiesSlider,
  FetchingError,
  HeroPropertySlider,
  PerksSection,
  PropertyEvaluationSection,
  ServicesBannersSection,
} from '@brick/ui-components';
import { Box, Stack } from '@chakra-ui/react';
import { GetStaticProps } from 'next';
import { dehydrate, QueryClient } from 'react-query';
import { LayoutPopulated } from '../components/LayoutPopulated/LayoutPopulated';
import { getFilterOptionsQuery } from '../components/Property/PropertyFilter/hooks/getFilterOptionsQuery';
import { PropertyFilter } from '../components/Property/PropertyFilter/PropertyFilter';
import { getLatestPropertiesQuery } from '../components/Property/PropertyFilter/utils/getLatestPropertiesQuery';
import { mapQueryParamsToFilterQuery } from '../components/Property/PropertyFilter/utils/mapQueryParamsToFilterQuery';
import { featuredBlogsContent } from '../content/blog';
import {
  contactCtaHeadingSectionContent,
  featuredPropertiesSliderContent,
  homePagePerksSectionHeading,
  propertyEvaluationSectionHeaderContent,
  servicesBannersSectionContent,
} from '../content/global';
import { fetchData } from '../lib/api/fetcher';
import {
  FEATURED_PROPERTIES_NUMBER,
  LATEST_PROPERTIES_NUMBER,
  SECTION_SPACING,
} from '../lib/const/const';
import { FEATURED_BLOGS_QUERY } from '../lib/features/blog/const/const';
import { mapBlogDataToBlogCards } from '../lib/features/blog/utils/mapBlogDataToBlogCards';
import { mapDataToOptions } from '../lib/features/forms/utils/mapDataToOptions';
import { mapDataToPerkCards } from '../lib/features/pages/utils/mapDataToPerkCards';
import { useLatestProperties } from '../lib/features/property/hooks/useLatestProperties';
import { mapProjectDataToPropertyCard } from '../lib/features/property/utils/mapProjectDataToPropertyCard';
import {
  useGetBlogsQuery,
  useGetHomePageQuery,
  useGetPropertiesQuery,
  useGetPropertyCategoriesQuery,
  useGetPropertyFilterOptionsQuery,
  useSubmitPropertyEvaluationRequestMutation,
} from '../lib/sdk/sdk';

const IndexPage = () => {
  const { properties: latestProperties } = useLatestProperties({
    limit: LATEST_PROPERTIES_NUMBER,
  });

  const { data: blogData } = useGetBlogsQuery(FEATURED_BLOGS_QUERY);
  const { data: categoriesData } = useGetPropertyCategoriesQuery();
  const { data: pageData } = useGetHomePageQuery();

  if (!latestProperties || !blogData || !categoriesData || !pageData) {
    return <FetchingError />;
  }
  const perkCards = mapDataToPerkCards(pageData.homePage.data.attributes.cards);
  const projectData = pageData.homePage.data.attributes.featuredProperties.map(
    (project) => project.project.data
  );

  const heroProperties = mapProjectDataToPropertyCard({
    projects: projectData,
    excludeStatusWatermark: true,
  });

  const blogs = mapBlogDataToBlogCards(blogData.blogs.data);

  return (
    <LayoutPopulated seo={{ pageName: 'Domov' }}>
      <Stack spacing={SECTION_SPACING} mb={SECTION_SPACING}>
        <Stack spacing={{ base: 4, md: 8 }}>
          <HeroPropertySlider properties={heroProperties} />
          <PropertyFilter showAllFields={false} />
        </Stack>
        <PerksSection heading={homePagePerksSectionHeading} perks={perkCards} />
        <Stack spacing={{ base: 4, md: 8 }}>
          <ContactCtaHeadingSection
            contactCtaHeading={{
              ...contactCtaHeadingSectionContent.contactCtaHeading,
              showBorder: true,
            }}
          />
          <ServicesBannersSection {...servicesBannersSectionContent} />
        </Stack>
        <Box>
          <FeaturedPropertiesSlider
            {...featuredPropertiesSliderContent}
            properties={latestProperties}
          />
          <PropertyEvaluationSection
            submitMutation={useSubmitPropertyEvaluationRequestMutation}
            propertyCategoryOptions={mapDataToOptions(
              categoriesData,
              'categories'
            )}
            formHeader={propertyEvaluationSectionHeaderContent}
          />
        </Box>
        <FeaturedBlogs {...featuredBlogsContent} blogs={blogs} />
      </Stack>
    </LayoutPopulated>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const qc = new QueryClient();

  // prefetch page
  await qc.prefetchQuery(
    useGetHomePageQuery.getKey(),
    fetchData(useGetHomePageQuery.document)
  );

  // prefetch filter options
  await qc.prefetchQuery(
    useGetPropertyFilterOptionsQuery.getKey(getFilterOptionsQuery()),
    fetchData(useGetPropertyFilterOptionsQuery.document)
  );

  // prefetch featured properties
  const query = mapQueryParamsToFilterQuery({}, FEATURED_PROPERTIES_NUMBER);
  await qc.prefetchQuery(
    useGetPropertiesQuery.getKey(query),
    fetchData(useGetPropertiesQuery.document, query)
  );

  // prefetch hero properties
  const latestPropertiesQuery = getLatestPropertiesQuery(
    LATEST_PROPERTIES_NUMBER
  );
  await qc.prefetchQuery(
    useGetPropertiesQuery.getKey(latestPropertiesQuery),
    fetchData(useGetPropertiesQuery.document, latestPropertiesQuery)
  );

  // prefetch blogs
  await qc.prefetchQuery(
    useGetBlogsQuery.getKey(FEATURED_BLOGS_QUERY),
    fetchData(useGetBlogsQuery.document, FEATURED_BLOGS_QUERY)
  );

  // prefetch property categories for evaluation form
  await qc.prefetchQuery(
    useGetPropertyCategoriesQuery.getKey(),
    fetchData(useGetPropertyCategoriesQuery.document)
  );

  return {
    props: {
      dehydratedState: dehydrate(qc),
    },
  };
};

export default IndexPage;
