import { mapUrlToStrapi } from 'apps/client/lib/utils/mapUrlToStrapi';
import { PerkCardProps } from 'libs/ui-components/src/lib/Sections/PerksSection/PerkCard';

export const mapDataToPerkCards = (data: any) => {
  return data.map((d) => {
    const perkCards: PerkCardProps = {
      title: d.title,
      description: d.description,
      items: d.items.map((i) => i.content),
      icon: mapUrlToStrapi(d.icon.data?.attributes.url),
    };
    return perkCards;
  });
};
