export const INITIAL_BLOG_NUMBER_LIMIT = 8;
export const BLOG_NUMBER_LIMIT_INCREMENT = 4;
export const INITIAL_BLOG_QUERY = {
  sort: ['publishedAt:desc'],
  limit: INITIAL_BLOG_NUMBER_LIMIT,
};

export const FEATURED_BLOGS_QUERY = {
  sort: ['publishedAt:desc'],
  limit: 3,
};

export const DEFAULT_AUTHOR = 'BRICK reality';
