import { Option } from '@brick/forms';

export const mapDataToOptions = (data: any, entityName: string) => {
  return data[entityName].data.map((i) => {
    const option: Option = {
      value: Number(i.id),
      text: i.attributes.name,
    };
    return option;
  });
};
