import { getLatestPropertiesQuery } from 'apps/client/components/Property/PropertyFilter/utils/getLatestPropertiesQuery';
import { useGetPropertiesQuery } from 'apps/client/lib/sdk/sdk';
import { mapProjectDataToPropertyCard } from '../utils/mapProjectDataToPropertyCard';

export interface UsePropertiesProps {
  limit?: number;
}

export const useLatestProperties = (props: UsePropertiesProps) => {
  const { limit } = props;
  const query = getLatestPropertiesQuery(limit);

  const { data, isPreviousData } = useGetPropertiesQuery(query, {
    keepPreviousData: true,
  });

  if (!data) return { properties: undefined, isLoading: false };
  let projectData = data.projects.data;

  let properties = mapProjectDataToPropertyCard({
    projects: projectData,
    excludeStatusWatermark: true,
  });

  return {
    properties,
    isLoading: isPreviousData,
    total: data.projects.meta.pagination.total,
  };
};
