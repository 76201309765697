import { menuRoutes } from 'apps/client/lib/routes/routes';
import { GetBlogsQuery } from 'apps/client/lib/sdk/sdk';
import { mapUrlToStrapi } from 'apps/client/lib/utils/mapUrlToStrapi';
import { BlogCardProps } from 'libs/ui-components/src/lib/Blog/BlogList/components/BlogCard';
import { DEFAULT_AUTHOR } from '../const/const';

export const getBlogSubitile = (
  blog: GetBlogsQuery['blogs']['data']['0']['attributes']
) => {
  const publishedDateFormatted = new Date(blog.publishedAt).toLocaleDateString(
    'sk-SK'
  );
  return `Autor: ${
    blog.author?.data?.attributes?.firstName
      ? `${blog.author?.data?.attributes.firstName} ${blog.author?.data?.attributes.lastName}`
      : DEFAULT_AUTHOR
  }  | Zverejnené: ${publishedDateFormatted}`;
};

export const mapBlogDataToBlogCards = (
  blogData: GetBlogsQuery['blogs']['data'],
  external = false
) => {
  if (!blogData) {
    return;
  }

  const blogs = blogData.map((blog) => {
    const b = blog.attributes;

    const blogCard: BlogCardProps = {
      title: b.title,
      subtitle: getBlogSubitile(b),
      description: b.description || b.body,
      image: mapUrlToStrapi(b.featuredImage?.data?.attributes.url),
      href: `${menuRoutes.news.href}/${b.slug}`,
      external: external,
    };
    return blogCard;
  });

  return blogs;
};
