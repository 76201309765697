import { ProjectFiltersInput } from 'apps/client/lib/sdk/sdk';
import { ACTIVE_PROPERTY_STATUS_FILTER } from '../const/defaults';

export const getLatestPropertiesQuery = (limit?: number) => {
  const filters: ProjectFiltersInput = {};
  const sort = [];
  filters['status'] = ACTIVE_PROPERTY_STATUS_FILTER;
  filters['publishOnPortal'] = { eq: true };
  sort.push('createdAt:desc');

  return { filters: filters, sort: sort, pagination: { limit } };
};
