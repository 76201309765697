import { menuRoutes } from '../lib/routes/routes';

export const featuredBlogsContent = {
  heading: {
    title: 'AKTUÁLNE ČLÁNKY ',
    description:
      'Všetky dôležité novinky a hodnotné informácie z prostredia realít na jednom mieste.',
  },
  cta: {
    href: menuRoutes.news.href,
    children: 'Všetky novinky a informácie',
    external: true,
  },
};
